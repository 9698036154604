@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
  font-family: 'Barlow',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F5F5;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bg-main-hasselt{
  background-color: #F0F5F5;
}
.bg-button{
  background-color: #398284;
}
.bg-button:hover{
  background-color: #1D585A;
}
.bg-button-dark{
  background-color: #1D585A;
}
input{
  outline-color: #398284;
}
.accent-checkbox{
  accent-color: #398284;
}